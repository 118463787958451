// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("select2")
require("jquery")
require("easy-autocomplete")
require("alpinejs")
require("dropzone")
require("chartkick")
require("chart.js")
// require( 'datatables.net' )
require('datatables.net-bs')(window, $)
require('datatables.net-buttons-bs')(window, $)
require('datatables.net-buttons/js/buttons.colVis.js')(window, $)
require('datatables.net-buttons/js/buttons.html5.js')(window, $)
require('datatables.net-buttons/js/buttons.print.js')(window, $)
require('datatables.net-responsive-bs')(window, $)
require('datatables.net-select')(window, $)
import "packs/people"
import "packs/signature"
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import '../stylesheets/application.scss'


// document.addEventListener("turbolinks:before-cache", function() {
			
//     var dataTable = $('#employee').DataTable( {
//             responsive: true
//         } )
//         .columns.adjust()
//         .responsive.recalc();
//         if (dataTable !== null) {
//             dataTable.destroy();
//             dataTable = null;
//            }
// } );